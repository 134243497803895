import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetchHasSession} from 'dp-account/src/features/id-token/repositories/account/fetch-has-session';
import {accountServerGraphQl} from '../../../const/env';
import {type UseAxiosMutationOptions} from '../../../types/react-query';
import {sessionDelete} from '../repositories/session-delete';
import {
  createUseHasSessionKey,
  type UseFetchHasSessionCache,
} from './use-fetch-has-session';

export const useSessionDelete = (options?: UseAxiosMutationOptions<void>) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const cache = queryClient.getQueryData<UseFetchHasSessionCache>(
        createUseHasSessionKey(),
      );

      if (cache?.data.data.hasSession) {
        return sessionDelete();
      }

      const response = await fetchHasSession(accountServerGraphQl);

      if (response.data.data.hasSession) {
        return sessionDelete();
      }
    },
    {
      ...options,
      onSuccess(...args) {
        void queryClient.refetchQueries(createUseHasSessionKey());

        void options?.onSuccess?.(...args);
      },
    },
  );
};
