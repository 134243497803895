import {signOut} from 'firebase/auth';
import {useMutation} from '@tanstack/react-query';
import {firebaseAuth} from '../auth/auth';
import {convertToJpFirebaseError} from '../services/convert-to-jp-firebase-error';
import {removeFirebaseUserIdFromCookie} from '../features/google-tag-manager/services/remove-firebase-user-id-from-cookie';
import {type UseFirebaseMutationOptions} from '../types/types';

export const useSignOut = (options?: UseFirebaseMutationOptions) => {
  return useMutation(
    async () => {
      return signOut(firebaseAuth);
    },
    {
      ...options,
      onSuccess(data, ...args) {
        removeFirebaseUserIdFromCookie();
        void options?.onSuccess?.(data, ...args);
      },
      onError(error, ...args) {
        const jpError = convertToJpFirebaseError(error);
        void options?.onError?.(jpError, ...args);
      },
    },
  );
};
