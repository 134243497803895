import {type Auth} from 'firebase/auth';
import {isSsr} from '../../../../utils/is';

export const getOrCreateIdTokenByFirebase = async (firebaseAuth: Auth) => {
  if (isSsr) {
    return undefined;
  }

  return new Promise<string | undefined>((resolve) => {
    if (firebaseAuth.currentUser) {
      resolve(firebaseAuth.currentUser.getIdToken());
      return;
    }

    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      unsubscribe();
      resolve(user?.getIdToken());
    });
  });
};
