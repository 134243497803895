import {type AxiosError} from 'axios';
import {type FirebaseError} from 'firebase/app';
import {type AuthError} from 'firebase/auth';
import {useSignOut as useSignOutFromFirebase} from '../../firebase-core/src/hooks/use-sign-out';
import {useSessionDelete} from './use-session-delete';

type UseSignOutOptions = {
  onSuccess?: () => void;
  onError?: (
    key: 'firebase' | 'account',
    error: AxiosError | AuthError | FirebaseError | Error,
  ) => void;
};

type UseSignOutResult = {
  signOut: () => void;
  isSignOutLoading: boolean;
};

export const useSignOut = (options?: UseSignOutOptions): UseSignOutResult => {
  // DP AccountからのSignOut
  const {mutateAsync: sessionDelete, isLoading: isSessionDeleteLoading} =
    useSessionDelete({
      onError(error) {
        options?.onError?.('account', error);
      },
    });

  // FirebaseからのSignOut
  const {
    mutateAsync: signOutFromFirebase,
    isLoading: isSignOutLoadingFromFirebase,
  } = useSignOutFromFirebase({
    onError(error) {
      options?.onError?.('firebase', error);
    },
  });

  const signOut = () => {
    Promise.all([sessionDelete(), signOutFromFirebase()])
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(() => {
        options?.onSuccess?.();
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch(() => {
        // OnErrorで処理を行うため、ここでは何もしない
      });
  };

  return {
    signOut,
    isSignOutLoading: isSignOutLoadingFromFirebase || isSessionDeleteLoading,
  };
};
